var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "stage",
      staticClass: "upr-stage",
      class: {
        "upr-stage--pointer": _vm.draggable && _vm.isPointer,
        "upr-stage--disabled": !_vm.draggable,
      },
      style: _vm.stageStyle,
    },
    [
      _c(
        "v-stage",
        {
          ref: "stage",
          staticClass: "upr-stage__konva",
          attrs: { config: _vm.configKonva },
          on: {
            mousedown: _vm.handleStageMouseDown,
            touchstart: _vm.handleStageMouseDown,
          },
        },
        [
          _c(
            "v-layer",
            [
              _vm._l(_vm.texts, function (item) {
                return _c("v-text", {
                  key: item.id,
                  attrs: {
                    config: {
                      text: _vm.getText(item.value),
                      x: item.x,
                      y: item.y,
                      draggable: _vm.draggable,
                      fontSize: _vm.fontSize,
                      fontStyle: "bold",
                      fontFamily: "Sofia Pro",
                      fill: "black",
                      name: item.id,
                    },
                  },
                  on: {
                    dragend: function ($event) {
                      return _vm.onDragEnd(item, $event)
                    },
                    dragstart: function ($event) {
                      _vm.isDragging = true
                    },
                    mouseover: function ($event) {
                      _vm.isPointer = true
                    },
                    mouseout: function ($event) {
                      _vm.isPointer = false
                    },
                  },
                })
              }),
              _vm.draggable
                ? _c("v-transformer", {
                    ref: "transformer",
                    attrs: {
                      config: { resizeEnabled: false, rotateEnabled: false },
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm.selected && !_vm.isDragging && _vm.draggable
        ? _c("SvgIcon", {
            staticClass: "upr-stage__remove",
            style: { top: _vm.selected.y, left: _vm.selected.x },
            attrs: { name: "trash-regular", size: "large" },
            on: { click: _vm.onDelete },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
























































import Vue from "vue"
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  toRefs,
  ref,
  watch,
  nextTick
} from "@vue/composition-api"
import VueKonva from "vue-konva"

Vue.use(VueKonva)

import useStore from "@/use/useStore"

import useUPRRef from "./useUPRRef"

export default defineComponent({
  name: "UnconditionalPositiveRegardStage",

  props: {
    userID: {
      type: String,
      default: undefined
    },
    encrypted: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const { userID, encrypted, draggable } = toRefs(props)
    const { store } = useStore()

    const mission = computed(() => store.getters.getCurrentMission)

    const { uprRef } = useUPRRef(userID)

    const stage = ref(null)
    const transformer = ref(null)
    const selected = ref(null)
    const isDragging = ref(null)
    const isPointer = ref(null)
    const data = ref({})

    const canvasWidth = ref(200)
    const canvasHeight = ref(200)
    const configKonva = computed(() => ({
      width: canvasWidth.value,
      height: canvasHeight.value
    }))

    const texts = computed(() =>
      Object.entries(data.value).map(([id, item]) => ({
        // @ts-expect-error TODO:
        value: item.value,
        // @ts-expect-error TODO:
        x: (canvasWidth.value / 100) * item.x,
        // @ts-expect-error TODO:
        y: (canvasHeight.value / 100) * item.y,
        id
      }))
    )

    const fontSize = computed(() => canvasHeight.value / 11.3)

    const stageStyle = computed(() => ({
      backgroundImage: `url(${mission.value.photo})`
    }))

    function onDragEnd(item, event) {
      isDragging.value = false

      selected.value = {
        ...item,
        x: event.target.x(),
        y: event.target.y()
      }

      uprRef.value.update({
        [`${item.id}/x`]: event.target.x() / (canvasWidth.value / 100),
        [`${item.id}/y`]: event.target.y() / (canvasHeight.value / 100)
      })
    }

    function onDelete() {
      uprRef.value.update({
        [selected.value.id]: null
      })

      selected.value = null
    }

    function handleStageMouseDown(e) {
      if (!draggable.value) return

      if (e.target === e.target.getStage()) {
        selected.value = null
        updateTransformer()
        return
      }

      const clickedOnTransformer =
        e.target.getParent().className === "Transformer"
      if (clickedOnTransformer) {
        return
      }

      const name = e.target.name()
      const textNode = texts.value.find(item => item.id === name)
      if (textNode) {
        selected.value = textNode
      } else {
        selected.value = null
      }
      updateTransformer()
    }

    function updateTransformer() {
      if (!draggable.value) return

      const transformerNode = transformer.value?.getNode()
      const stage = transformerNode?.getStage()

      const selectedNode = stage.findOne("." + selected.value?.id)

      if (selectedNode === transformerNode.node()) {
        return
      }

      if (selectedNode) {
        transformerNode.nodes([selectedNode])
      } else {
        transformerNode.nodes([])
      }
    }

    onMounted(() => {
      canvasWidth.value = stage.value.offsetWidth
      canvasHeight.value = stage.value.offsetHeight
    })

    function onSnapshot(snapshot) {
      data.value = snapshot.val() || {}
    }

    function getText(string) {
      return encrypted.value ? string.replace(/\S/g, "-") : string
    }

    watch(
      uprRef,
      (newValue, oldValue) => {
        oldValue?.off("value", onSnapshot)
        newValue.on("value", onSnapshot)
      },
      { immediate: true }
    )

    watch(texts, (newValue, oldValue) => {
      if (
        newValue.length > oldValue.length &&
        oldValue[0]?.id === newValue[0]?.id
      ) {
        selected.value = newValue[newValue.length - 1]
      }

      if (oldValue[0]?.id !== newValue[0]?.id) {
        selected.value = null
      }

      nextTick(() => {
        updateTransformer()
      })
    })

    onBeforeUnmount(() => uprRef.value.off("value", onSnapshot))

    return {
      handleStageMouseDown,
      transformer,
      isDragging,
      selected,
      stage,
      onDragEnd,
      onDelete,
      canvasWidth,
      canvasHeight,
      stageStyle,
      configKonva,
      getText,
      texts,
      fontSize,
      isPointer
    }
  }
})

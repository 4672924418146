import { random } from "lodash"

import { db } from "@/firebase"
import useStore from "@/use/useStore"

import { computed } from "@vue/composition-api"

export default function useUPRRef(userID) {
  const { store } = useStore()
  const missionID = computed(() => store.getters.currentMission)
  const roomID = computed(() => store?.getters.game?.id)
  const uprRef = computed(() =>
    db
      .auxiliary()
      .ref(`_room/${roomID.value}/upr/${missionID.value}/user/${userID.value}`)
  )

  function onSubmit(value) {
    uprRef.value.push({ value, x: random(10, 70), y: random(10, 70) })
  }

  return {
    onSubmit,
    uprRef
  }
}
